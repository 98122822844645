html {
  height: 100%;
  min-height: 400px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

header {
  background: #d2edf4;
  background-image: linear-gradient(to bottom, #e1edf5, #e1e5f0 100%);
  padding: 20px 15px 15px 15px;
  position: relative;
}

body {
  font-family: sans-serif;
  background-color: #315481;
  background-image: linear-gradient(to bottom, #d1d4d1, #918e82 100%);
  background-attachment: fixed;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 10;
  font-size: 14px;
  overflow: hidden;
}

h2 {
  display: inline-block;
  margin-top: 5px;
}

.clearButton {
  position: relative;
  margin-left: 15px;
  top: -0.3em;
}

.addRepButton {
  position: relative;
  margin-left: 15px;
  top: -0.3em;
}

#repText {
  position: relative;
  top: -4px;
}

#login-buttons {
  display: block;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}
